import React from "react"
import { graphql } from "gatsby"
import PropTypes from "prop-types"
import styled from "styled-components"

import SEO from "../components/seo"
import Layout from "../components/layout"
import Header from "../components/header"
import Menu from "../components/menu"
import SummaryTable from "../components/summary-table"

import {
  Price,
  MenuSubheading,
  MenuThirdHeading,
  Copy,
} from "../components/typography"

const Content = styled.div`
  display: grid;
  row-gap: 20px;
  padding-bottom: 90px;

  & > * {
    margin-left: 25px;
    margin-right: 25px;
  }

  @media screen and (min-width: 768px) {
    grid-template-columns: repeat(2, 1fr);
    column-gap: 30px;

    & > * {
      margin-left: 30px;
      margin-right: 30px;
    }

    & > :first-child,
    & > :nth-child(2) {
      grid-column: 1 / -1;
    }
  }

  @media screen and (min-width: 992px) {
    margin: 0 auto;
    width: 825px;
    & > * {
      margin-left: 0;
      margin-right: 0;
    }
  }
`

const HorizontalRule = styled.hr`
  margin-top: 40px;
  margin-bottom: 45px;
  border: none;
  height: 1px;
  width: 160px;
  background-color: hsl(0, 0%, 20%);
  grid-column: 1 / -1;
`

const PriceBlock = styled.div`
  & > :first-child {
    margin-top: 0;
  }
`

const PackageIncludes = styled.div`
  & > :first-child {
    margin-bottom: 15px;
  }
  & > ul {
    list-style: outside "·  ";
  }
`

const Promotion = ({
  location,
  data: {
    contentfulPromotion: {
      seoTitle,
      seoDescription,
      seoKeywords,
      image,
      title,
      description: { description },
      availability,
      price,
      menuContent,
      disclaimer,
      packageInclusions,
    },
  },
}) => (
  <Layout>
    <SEO
      title={seoTitle}
      description={seoDescription}
      meta={[{ name: "keywords", content: seoKeywords.join(", ") }]}
    />
    <Header
      contentOnRight={false}
      image={image}
      title={title}
      text={description}
      backLink={
        typeof window !== 'undefined' 
          ? location?.state?.referrer || "/promotions/" 
          : "/promotions/"
      }
    />
    <Content>
      <SummaryTable
        items={[
          { title: "Availability", value: availability },
          { title: "Price", value: price },
        ]}
      />
      <Menu menuContent={menuContent} />
      <HorizontalRule />
      {price ? (
        <PriceBlock>
          <MenuSubheading>Price</MenuSubheading>
          <Price>{price}</Price>
          {disclaimer ? <Copy>{disclaimer}</Copy> : null}
        </PriceBlock>
      ) : null}
      {packageInclusions ? (
        <PackageIncludes>
          <MenuThirdHeading>Package Inclusions</MenuThirdHeading>
          <ul>
            {packageInclusions.map((packageInclusion, index) => (
              <li key={index}>
                <Copy>{packageInclusion}</Copy>
              </li>
            ))}
          </ul>
        </PackageIncludes>
      ) : null}
    </Content>
  </Layout>
)

export default Promotion

Promotion.propTypes = {
  location: PropTypes.shape({
    state: PropTypes.shape({
      referrer: PropTypes.string,
    }),
  }),
  data: PropTypes.shape({
    contentfulPromotion: PropTypes.shape({
      seoTitle: PropTypes.string,
      seoDescription: PropTypes.string,
      seoKeywords: PropTypes.array,
      image: PropTypes.shape({
        description: PropTypes.string,
        file: PropTypes.shape({
          url: PropTypes.string,
          details: PropTypes.shape({
            image: PropTypes.shape({
              height: PropTypes.number,
              width: PropTypes.number,
            }),
          }),
        }),
        fluid: PropTypes.object,
      }),
      title: PropTypes.string,
      description: PropTypes.shape({
        description: PropTypes.string,
      }),
      availability: PropTypes.string,
      price: PropTypes.string,
      menuContent: PropTypes.arrayOf(
        PropTypes.shape({
          __typename: PropTypes.string,
          menuSectionHeading: PropTypes.string,
          menuSectionDescription: PropTypes.shape({
            menuSectionDescription: PropTypes.string,
          }),
          displayName: PropTypes.string,
          description: PropTypes.shape({
            description: PropTypes.string,
          }),
        })
      ),
      disclaimer: PropTypes.string,
      packageInclusions: PropTypes.arrayOf(PropTypes.string),
    }),
  }),
}

export const query = graphql`
  query PromotionQuery($slug: String!) {
    contentfulPromotion(slug: { eq: $slug }) {
      slug
      seoTitle
      seoDescription
      seoKeywords
      image {
        description
        file {
          url
          details {
            image {
              height
              width
            }
          }
        }
        fluid(maxWidth: 2400) {
          ...GatsbyContentfulFluid_withWebp
        }
      }
      title
      description {
        description
      }
      availability
      price
      menuContent {
        ... on ContentfulMenuHeading {
          menuSectionHeading
          menuSubsectionHeading
          menuDescription {
            menuDescription
          }
        }
        ... on ContentfulMenuItem {
          displayName
          description {
            description
          }
        }
      }
      disclaimer
      packageInclusions
    }
  }
`
